import hsl from "hsl-to-hex";

// return a random number within a range
export function random(min, max) {
  return Math.random() * (max - min) + min;
}

// ColorPalette class
export class ColorPalette {
  constructor(colorMode) {
    this.colorMode = colorMode;
    this.setColors();
  }

  setColors() {
    const dayPalette = [
      hsl(315, 30, 80),
      hsl(161, 40, 80),
      hsl(15, 40, 70),
      hsl(15, 40, 60)
    ];
    const nightPalette = [
      hsl(215, 40, 5),
      hsl(215, 40, 15),
      hsl(315, 35, 25),
      hsl(15, 45, 32)
    ];

    if (this.colorMode === "light") {
      this.colorChoices = dayPalette;
    } else {
      this.colorChoices = nightPalette;
    }
  }

  randomColor() {
    // pick a random color
    return this.colorChoices[~~random(0, this.colorChoices.length)].replace(
      "#",
      "0x"
    );
  }
}

// get current hour
export function getCurrentHour() {
  const now = new Date();
  const hour = now.getHours();
  return hour;
}
