import "./styles.css";

export default function Layout(props) {
  const { children } = props;
  return <div className="Layout">{children}</div>;
}

export function Aside(props) {
  const { children } = props;
  return <aside className="Layout-aside">{children}</aside>;
}

Aside.displayName = "Aside";
Layout.Aside = Aside;

export function Hero(props) {
  const { children } = props;
  return <div className="Layout-hero">{children}</div>;
}

Hero.displayName = "Hero";
Layout.Hero = Hero;

export function Main(props) {
  const { children } = props;
  return <div className="Layout-main">{children}</div>;
}

Main.displayName = "Main";
Layout.Main = Main;

export function Footer(props) {
  const { children } = props;
  return <div className="Layout-footer">{children}</div>;
}

Footer.displayName = "Footer";
Layout.Footer = Footer;
