import { Heading } from "../../components";
import "./styles.css";

export default function Footer() {
  return (
    <footer className="Footer">
      <p className="Footer-text">Made by a human &copy; 2023</p>
    </footer>
  );
}
