import { Heading, Link } from "../../components";
import "./styles.css";

export default function Hero() {
  return (
    <nav className="Nav">
      <div className="Nav-group">
        <Heading type="sm">Don't be shy, let's talk.</Heading>
        <ul className="Nav-list">
          <li className="Nav-listItem">
            <Link external to="https://www.linkedin.com/in/amberfeinerman/">
              LinkedIn
            </Link>
          </li>
          <li className="Nav-listItem">
            <Link external to="https://twitter.com/amberfeinerman">
              Twitter
            </Link>
          </li>
          <li className="Nav-listItem">
            <Link to="mailto:hiamberstickel@gmail.com">Email</Link>
          </li>
        </ul>
      </div>

      <div className="Nav-group">
        <Heading type="sm">
          <h3>Want to learn more?</h3>
        </Heading>
        <Link to="https://read.cv/amberfeinerman" external>
          View Resumé
        </Link>
      </div>
    </nav>
  );
}
