import "./styles.css";

export default function Section(props) {
  const { children } = props;
  return <div className="Section">{children}</div>;
}

export function Title(props) {
  const { children } = props;
  return <div className="Section-title">{children}</div>;
}

Title.displayName = "Title";
Section.Title = Title;
