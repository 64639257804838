import { Heading } from "../../components";
import "./styles.css";

export default function Hero() {
  return (
    <section className="Hero">
      <Heading type="display">
        <h2 className="Hero-heading">Amber Feinerman</h2>
      </Heading>
      <p className="Hero-subhead">
        Hi there—I'm a multidisciplinary designer and developer based out of the
        sunshine state. Over the past ten years I've worked for some amazing
        companies like Intuit, Sprout Social, Shopify, IBM, and Indeed.
      </p>
    </section>
  );
}
