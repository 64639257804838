import "./styles.css";

export default function Link(props) {
  const { children, external, to } = props;
  return (
    <a className="Link" href={to} target={external ? `_blank` : `_self`}>
      {children}
    </a>
  );
}
