import React, { useEffect, useMemo, useState } from "react";
import MeshGradient from "mesh-gradient.js";
import { ColorPalette } from "../../utils";
import { SVGfilter } from "./components";
import "./styles.css";

export default function GradientCanvas(props) {
  const { colorMode } = props;
  const gradient = useMemo(() => new MeshGradient(), []);
  const canvasId = "my-canvas";
  const fps = 60;
  const defaultColorPalette = new ColorPalette(colorMode);
  const [pos, setPos] = useState(1);
  const [colorPalette] = useState(defaultColorPalette.colorChoices);

  function animate() {
    const newPos = (curr) => curr + 1 / 100;
    setPos(newPos);
    setTimeout(() => {
      requestAnimationFrame(animate);
    }, 1000 / fps);
  }

  useEffect(() => {
    gradient.initGradient("#" + canvasId, colorPalette);
    animate();
  }, [gradient]);

  useEffect(() => {
    gradient.changePosition(pos);
  }, [pos, gradient]);

  return (
    <div className="GradientCanvas">
      <div className="GradientCanvas-filter">
        <SVGfilter />
      </div>
      <canvas id={canvasId} style={{ width: "100vw", height: "100vh" }} />
    </div>
  );
}
