import "./styles.css";

export default function ContentContainer(props) {
  const { children, size } = props;
  return (
    <div className={`ContentContainer ContentContainer--${size}`}>
      {children}
    </div>
  );
}
