import React from "react";

export default function SVGfilter() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" opacity=".7" viewBox="0 0 700 700">
      <defs>
        <filter
          id="a"
          x="-20%"
          y="-20%"
          width="140%"
          height="140%"
          filterUnits="objectBoundingBox"
          primitiveUnits="userSpaceOnUse"
          colorInterpolationFilters="linearRGB"
        >
          <feTurbulence
            type="fractalNoise"
            baseFrequency=".2"
            numOctaves="4"
            seed="15"
            stitchTiles="stitch"
            x="0%"
            y="0%"
            width="100%"
            height="100%"
            result="turbulence"
          />
          <feSpecularLighting
            surfaceScale="35"
            specularConstant="2.4"
            specularExponent="20"
            lightingColor="#333333"
            x="0%"
            y="0%"
            width="100%"
            height="100%"
            in="turbulence"
            result="specularLighting"
          >
            <feDistantLight azimuth="3" elevation="151" />
          </feSpecularLighting>
        </filter>
      </defs>
      <path fill="transparent" d="M0 0h700v700H0z" />
      <path fill="#333333" filter="url(#a)" d="M0 0h700v700H0z" />
    </svg>
  );
}
