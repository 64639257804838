import React, { useState } from "react";
import {
  ContentContainer,
  GradientCanvas,
  Footer,
  Heading,
  Hero,
  Layout,
  Nav
} from "./components";
import { getCurrentHour } from "./utils";
import "./styles.css";

export default function App() {
  const hour = getCurrentHour();
  const [colorMode, setColorMode] = useState(
    hour > 6 && hour < 19 ? "light" : "dark"
  );

  const toggleColorMode = () => {
    colorMode !== "dark" ? setColorMode("dark") : setColorMode("light");
  };

  return (
    <div className={`App App--${colorMode}`}>
      {colorMode === "light" && <GradientCanvas colorMode={"light"} />}
      {colorMode === "dark" && <GradientCanvas colorMode={"dark"} />}
      <div className={`App-wrapper App-wrapper--${colorMode}`}>
        <button
          aria-label="Toggle dark mode"
          className="ToggleColorBtn"
          onClick={toggleColorMode}
        >
          <span className="ToggleColorBtn-icon"></span>
        </button>
        <Layout>
          <Layout.Hero>
            <Hero />
          </Layout.Hero>
          <Layout.Aside>
            <Nav />
          </Layout.Aside>
          <Layout.Main>
            <ContentContainer size="lg">
              <Heading type="md">
                I've been called a lot of things throughout my career.
              </Heading>
              <p>
                Designer or developer, I'm not picky about titles. Ultimately,
                I'm a problem solver who uses my hybrid design and development
                skills to craft digital, cross-platform experiences with an
                affinity for simple, intuitive designs.
              </p>
              <p>
                Over the last ten years, I've built projects ranging from
                company websites and immersive experiences for small agencies to
                leading design systems intiatives for enterprise tech companies.
                I thrive when I am able to bridge the gap between design and
                engineering teams to create modular, reusable, and flexible
                interfaces and systems.
              </p>
            </ContentContainer>
          </Layout.Main>
          <Layout.Footer>
            <Footer />
          </Layout.Footer>
        </Layout>
      </div>
    </div>
  );
}
